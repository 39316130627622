import type {ReactElement} from 'react';
import React from 'react';
import './modal-container.scss';
import {ModalRender} from '@Components/modal-render/modal-render';
import {useAppSelector} from '@/hooks';

export function ModalContainer(): ReactElement {
  const modalsData = useAppSelector((state) => {
    return state.modals.modalsHashmap;
  });

  const getModals = (): Array<ReactElement> => {
    const modals = [];
    for (const [modalId] of Object.entries(modalsData)) {
      modals.push(<ModalRender key={modalId} modalId={modalId} />);
    }
    return modals;
  };

  return <>{getModals()}</>;
}

export const isModalOpen = (): boolean => {
  for (const [, modalData] of Object.entries(window.PMW.redux.store.getState().modals.modalsHashmap)) {
    if (modalData.isOpen) {
      return true;
    }
  }
  return false;
};
