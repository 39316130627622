import type {MouseEventHandler, ReactElement} from 'react';
import React from 'react';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {Icon} from '@Components/icon-v2';
import type {PanelHeaderActionProps} from '../panel-header-action';
import {PanelHeaderAction} from '../panel-header-action';
import styles from './panel-header-actions.module.scss';

interface PanelHeaderActionsProps {
  actions: Array<PanelHeaderActionProps>;
  showCloseButton?: boolean;
  closeButtonIconSize?: IconSize;
  onClose?: MouseEventHandler;
  className?: string;
  closeIconClasses?: string;
}

export function PanelHeaderActions({
  className = '',
  closeButtonIconSize = IconSize.SIZE_ICON_24,
  showCloseButton = true,
  onClose = (): void => {},
  actions = [],
  ...props
}: PanelHeaderActionsProps): ReactElement {
  const actionItems = actions.map((actionProps) => {
    return <PanelHeaderAction {...actionProps} />;
  });
  if (actionItems.length && showCloseButton) {
    actionItems.push(<span key="divider" className={`spacing-m-l-3 spacing-m-r-3 ${styles.divider}`} />);
  }
  if (showCloseButton) {
    actionItems.push(
      <Icon
        type={IconType.GHOST}
        shape={IconShape.SQUARE}
        key="panel-header-close-button"
        onClick={onClose}
        icon="icon-close"
        size={closeButtonIconSize}
        className={props.closeIconClasses}
      />
    );
  }

  return actionItems.length ? <div className={`flex-row-align-center ${className}`}>{actionItems}</div> : <div />;
}
